<template>
  <div class="page-wrap padding">
    <div v-if="!isMobileScreenExclude768 || $route.name === 'Profile'" class="main-content profile-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="profile-cnt">
              <div class="menu-wrap">
                <ProfileMenu />
              </div>
              <RouterView />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="page-wrap">
      <div class="main-content profile-data-page">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <RouterView />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProfileMenu from '@/components/partials/profile/ProfileMenu';

export default {
  name: 'ProfilePage',
  components: {
    ProfileMenu,
  },
  computed: {
    ...mapState(['isMobileScreenExclude768']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  @media (max-width: 768px) {
    padding: 0 15px !important;
  }
}

.padding {
  @media (max-width: 768px) {
    overflow-x: hidden !important;
    padding: 0 15px;
  }
}

.profile-data-page {
  @media (max-width: 768px) {
    align-items: baseline;
  }
}
</style>
