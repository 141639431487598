






















import { mapGetters, mapState } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import IProfileLink from '@/interfaces/IProfileLink';

import meQuery from '@/graphql/me/Me.query.gql';

import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

@Component({
  components: { TheArrowHorizIcon },
  computed: {
    ...mapState(['isMobileScreenExclude768', 'museumRole']),
    ...mapGetters(['isUserMembershipLapsed']),
  },
  apollo: {
    me: {
      query: meQuery,
      result(result: any) {
        this.userData = result.data.me;
      },
    },
  },
})
export default class ProfileMenu extends Vue {
  activePath = '';
  isMobileScreenExclude768: boolean;
  isUserMembershipLapsed: boolean;
  userData: any;

  get links(): IProfileLink[] {
    if (this.isUserMembershipLapsed) {
      return [
        {
          title: 'Account details',
          path: 'my-membership',
        },
        {
          title: 'Payment details',
          path: 'payment',
        },
        {
          title: 'Delete account',
          path: 'delete',
        },
      ];
    }

    let links: IProfileLink[];

    if (this.isMobileScreenExclude768) {
      links = [
        {
          title: 'My agenda',
          path: '/my-agenda',
        },
        {
          title: 'My art guides',
          path: '/all-art-guides',
        },
        {
          title: 'My favourites',
          path: '/all-favorites',
        },
      ];
    } else {
      links = [
        {
          title: 'Profile',
          path: 'profile',
        },
      ];
    }

    links.push(
      ...[
        {
          title: 'Contact preferences',
          path: 'contact-preferences',
        },
        {
          title: 'I’m interested in',
          path: 'interested-in',
        },
        {
          title: 'Notifications',
          path: 'notifications',
        },
        {
          title: 'Add special request',
          path: 'special-request',
        },
        {
          title: 'Account details',
          path: 'my-membership',
        },
        {
          title: 'Payment details',
          path: 'payment',
        },
        {
          title: 'Delete account',
          path: 'delete',
        },
      ]
    );

    return links.filter(
      (link: IProfileLink) => !this.museumRole || (this.museumRole && !['special-request'].includes(link.path))
    );
  }

  @Watch('$route', { deep: true })
  onRouteChange(val: any) {
    this.activePath = val.path;
  }

  created() {
    this.activePath = this.$route.path;
  }

  isActive(path: string) {
    const menuValue = path.replace(/\/$/g, '');
    const currentPath = this.activePath.replace(/\/$/g, '');

    if (menuValue) {
      return `/account/${menuValue}` === currentPath;
    }
    return '/account' === currentPath;
  }
}
